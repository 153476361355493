import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4><em parentName="h4">{`About Me`}</em></h4>
    <p>{`I'm a thirty-something, multi-lingual, passionately curious, deep-thinking, biological female.  `}</p>
    <h4><em parentName="h4">{`What You'll Find Here`}</em></h4>
    <p><strong parentName="p">{`Daily Blog`}</strong>{` - usually a 1 minute read.  My thought of the day. `}</p>
    <p><strong parentName="p">{`Actionable Philosophy`}</strong>{` - How to understand life better.`}</p>
    <p><strong parentName="p">{`Theories on Human Behaviour`}</strong>{` - My own included.  It's been a lifelong fascination.`}</p>
    <p><strong parentName="p">{`Book Reviews`}</strong>{` - My reading log.  Short summaries per book;  detailed notes for some of the more practical ones. `}</p>
    <p><strong parentName="p">{`Fasting`}</strong>{` - I've been fasting since before it was trendy, more than 17 years ago.  Prolonged water fasting, dry fasting, and other forms.  Information, tracking (e.g. blood glucose & ketone levels) & diaries will be found here. `}</p>
    <h4><em parentName="h4">{`2021: a year of challenges`}</em></h4>
    <p>{`Because why not? `}</p>
    <p><strong parentName="p">{`January Challenge:`}</strong>{` Create a website and publish a daily blog post. `}</p>
    <p><strong parentName="p">{`February Challenge:`}</strong>{` Read every day. `}</p>
    <p><strong parentName="p">{`March Challenge:`}</strong>{` Squat every day. `}</p>
    <p><strong parentName="p">{`April Challenge:`}</strong>{` Hang from a bar every day. `}<em parentName="p">{`(missed one day)`}</em></p>
    <p><strong parentName="p">{`May Challenge:`}</strong>{` Stomach crunches every day. `}</p>
    <p><strong parentName="p">{`June Challenge:`}</strong>{` Push-ups to failure every day. `}</p>
    <p><strong parentName="p">{`July Challenge:`}</strong>{` No challenge `}</p>
    <p><strong parentName="p">{`August Challenge:`}</strong>{` Writing about a theme each week `}</p>
    <p>{`Challenge Ideas?  I welcome them! Send an email to `}<a parentName="p" {...{
        "href": "mailto:hello@abbies.blog"
      }}>{`hello@abbies.blog`}</a>{` `}</p>
    <h4><em parentName="h4">{`Everyone thinks of changing the world, but no one thinks of changing himself.`}</em>{`        ~ Leo Tolstoy`}</h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      