/** @jsx jsx */
import { Fragment, useEffect, useState } from "react"
import { jsx, Link } from "theme-ui"
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata"
const Footer = () => {
  const { siteTitle } = useSiteMetadata()
  const scriptCode = `<script data-uid="f661d784de" src="https://deft-producer-256.ck.page/f661d784de/index.js"></script>`
  useEffect (()=> {
    const formScript = document.createElement('script')
    formScript.src = "https://deft-producer-256.ck.page/f661d784de/index.js"
    formScript.async = true
    formScript.setAttribute("data-uid","f661d784de")
    document.querySelector('.form-sub').appendChild(formScript);
  }, [])
  return (
    <Fragment>
      <div style={{margin:"0 auto" ,marginTop:"2rem", maxWidth:"720px"}} className="form-sub" />
    <footer
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `space-between`,
        mt: [2],
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
        flexDirection: [`column`, `column`, `row`],
        variant: `dividers.top`,
      }}
    >
      <div>
        &copy; {new Date().getFullYear()} by {siteTitle}. All rights reserved.
      </div>
      <div>
        <Link
          aria-label="Link to the theme's GitHub repository"
          href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-minimal-blog"
        >
          Theme
        </Link>
        {` `}
        by
        {` `}
        <Link aria-label="Link to the theme author's website" href="https://www.lekoarts.de/en">
          LekoArts
        </Link>
      </div>
    </footer>
          
    </Fragment>
  )
}

export default Footer
